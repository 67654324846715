@import '~four-nets/sass/colors';
@import '~four-nets/sass/four-nets-mixins';

.interscrollerWrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;

    width: 100vw;
    height: 600px;

    @supports (-webkit-touch-callout: none) {
        clip: rect(0px, auto, auto, 0px);
    }

    @supports not (-webkit-touch-callout: none) {
        clip-path: inset(0px);
    }
    overflow: hidden;

    &.interscrollerWrapperActive {
        display: block;
        @media #{$md} {
            display: none;
        }
    }

    @media #{$md} {
        display: none;
    }
}

.interscroller {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    transform: translateZ(0px);

    @media #{$interscroller} {
        // 60px is the header height
        top: 60px;
    }
}

.interscrollerPlaceholder {
    width: 100%;
    height: calc(100vh - 60px);

    &.interscrollerPlaceholderInactive {
        display: none;

        @media #{$md} {
            display: none;
        }
    }
    @media #{$md} {
        display: none;
    }
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.small {
        font-size: 15px;
        @media only screen and (max-width: 500px) {
            padding-top: 80px;
            transform: scale(0.85);
        }
    }

    &.empty {
        display: none;
    }
}

.label {
    width: 100%;
    margin-bottom: 2px;
    text-align: right;
    font-size: 0.75rem;
    color: $grey500;
}

.brandingMobile {
    display: none;

    @media only screen and (max-width: 1024px) {
        display: flex;
    }
}

.dfpBanner {
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
}
