@import '~four-nets/sass/four-nets-mixins';

.wrapper {
    position: relative;
    width: 100%;
}

.error {
    margin: 0;
    font-size: rem(13px);
    color: $red600;
    font-weight: 300;
    padding: 5px 0 0;

    &:last-of-type {
        padding: 5px 0 10px;
    }
}

.autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.117647) 0 1px 6px, rgba(0, 0, 0, 0.117647) 0 1px 4px;
    z-index: 10;
}

.textInput {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: $white;
    border-style: solid;
    border-width: 1px;

    &:hover {
        cursor: text;
    }

    :global(.sk-fading-circle) {
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        width: 20px;
        margin: 0 15px 0 0;
    }
}

.input {
    @include no-focus-outline;

    display: inline-block;
    vertical-align: middle;
    border: 0;
    background: none;
    padding: 10px;
    overflow: visible;
    font-family: inherit;
    width: 100%;
    font-size: 1rem;
}

.label {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 2;
}

@include theme-garden {
    .textInput {
        border-color: $grey430;

        &.focused {
            border-color: $green500;
        }

        &.invalid {
            border-color: $red600;
        }

        :global(.sk-circle) {
            &::before {
                background-color: $green500;
            }
        }
    }

    .input {
        color: $green900;
    }
}

@include theme-living {
    .textInput {
        border-color: $grey430;

        &.focused {
            border-color: $blue600;
        }

        &.invalid {
            border-color: $red600;
        }

        :global(.sk-circle) {
            &::before {
                background-color: $blue600;
            }
        }
    }

    .input {
        color: $grey600;
    }
}

@include theme-wedding {
    .textInput {
        border-color: $grey430;

        &.focused {
            border-color: $genoa;
        }

        &.invalid {
            border-color: $red600;
        }

        :global(.sk-circle) {
            &::before {
                background-color: $genoa;
            }
        }
    }

    .input {
        color: $grey600;
    }
}
