@import '~four-nets/sass/four-nets-mixins';

.avatar {
    width: 35px;
    height: 35px;

    border-radius: 5px;
    overflow: hidden;

    display: inline-block;
    text-decoration: none;

    @media #{$lg} {
        width: 50px;
        height: 50px;
    }
}

.link {
    text-decoration: none;
    display: inline-flex;
}

.svg.svg {
    fill: currentColor;

    svg,
    path:not([fill='none']) {
        fill: currentColor;
    }
}

@include theme-garden {
    .avatar {
        color: $green100;
    }
}

@include theme-living {
    .avatar {
        color: $blue200;
    }
}

@include theme-wedding {
    .avatar {
        color: $pink200;

        &.whiteBg {
            background-color: $white;
        }
    }
}
