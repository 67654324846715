@import '~four-nets/sass/four-nets-mixins';

$facebookIconSize: 20px;

.icon.icon {
    width: $facebookIconSize;
    height: $facebookIconSize;
    margin-right: 5px;
}

.facebookButton {
    padding: 5px 20px;

    background-color: #4267b2;
    border-radius: 3px;
    cursor: pointer;

    font-size: 1rem;
    color: white;
}

.facebookButtonInnerWrapper {
    height: $facebookIconSize;
    display: flex;
    align-content: center;
}

.facebookButtonText {
    line-height: $facebookIconSize;
}

