@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.routerProgress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
}

@include theme-garden {
    .loadingProgressBar :global(.mdc-linear-progress__bar-inner) {
        background-color: $green600;
        border-color: $green600;
    }
}

@include theme-living {
    .loadingProgressBar :global(.mdc-linear-progress__bar-inner) {
        background-color: $blue600;
        border-color: $blue600;
    }
}

@include theme-wedding {
    .loadingProgressBar :global(.mdc-linear-progress__bar-inner) {
        background-color: $pink900;
        border-color: $pink900;
    }
}
