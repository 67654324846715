@import '~four-nets/sass/four-nets-mixins';

.fileInput {
    width: 100%;

    position: relative;
    overflow: hidden;
    display: block;

    @media #{$lg} {
        width: 200px;
    }
}

.input {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    font-size: 60rem;
    cursor: pointer;

}
