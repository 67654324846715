@import '~four-nets/sass/four-nets-mixins';

.relativeWrapper {
    position: relative;
}

.overlayButton {
    width: 100%;
    height: 100%;

    border: 0;
    background: rgba(256, 256, 256, 0.6);

    position: absolute;
    top: 0;
    left: 0;

    cursor: pointer;

    &.grey {
        background: rgba($grey100, 0.6);
    }
}

.link {
    @include link;
}

@include theme-garden {
    .link {
        color: $purple600;
    }
}

@include theme-living {
    .link {
        color: $purple600;
    }
}

@include theme-wedding {
    .link {
        color: $purple600;
    }
}
