
@import '~lib/images/svg-image-view';

.image {
    fill: currentColor;

    svg,
    path:not([fill=none]) {
        fill: currentColor;
    }

    width: 20px;
    height: 20px;
}
