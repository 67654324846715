@import '~four-nets/sass/four-nets-mixins';

.searchInput.searchInput {
    width: 280px;
}

.input.input {
    max-width: 230px;
    height: 30px;
}

.iconWrapper.iconWrapper {
    min-width: 34px;
    min-height: 34px;
    padding: 7px;
}

@include theme-garden {
    .searchInput.searchInput {
        border-color: $green100;
        background-color: $green050;

        &.focused.focused {
            background-color: $white;
        }
    }
}

@include theme-living {
    .searchInput.searchInput {
        border-color: $blue800;
        background-color: $blue800;

        &.focused.focused {
            border-color: $grey100;
            background-color: $white;
        }
    }
}

@include theme-wedding {
    .searchInput.searchInput {
        width: 210px;

        border-color: $pink300;
        background-color: $pink300;

        &.focused.focused {
            border-color: $elm;
            background-color: $white;
        }
    }

    .iconWrapper.iconWrapper {
        margin-right: 10px;
        color: $elm;
    }
}
