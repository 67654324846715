@import '~lib/sass/rem';
@import '~lib/sass/media';

$LatoHairline: 100;
$LatoThin: 200;
$LatoLight: 300;
$LatoRegular: 400;
$LatoMedium: 500;
$LatoSemibold: 600;
$LatoBold: 700;
$LatoHeavy: 800;
$LatoBlack: 900;

@mixin text-font {
    font-size: rem(16px);

    @media #{$md} {
        font-size: rem(16px);
    }
}

@mixin title-font {
    font-size: rem(24px);
    font-weight: $LatoBlack;

    text-transform: uppercase;

    @media #{$md} {
        font-size: rem(24px);
        font-weight: $LatoBlack;
    }
}

@mixin secondary-title-font {
    font-size: rem(24px);
    font-weight: $LatoBold;
}

@mixin h3-font {
    font-size: rem(20px);
    font-weight: $LatoMedium;
}

@mixin side-panel-title-font {
    font-size: rem(18px);
    font-weight: 900;
    text-transform: uppercase;

    @include theme-living {
        background-color: $blue200;
        color: $grey700;
    }
}
