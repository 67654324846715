@import '~four-nets/sass/four-nets-mixins';

.navigationMenu {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: auto;
}

.navigation,
.list {
    margin: 0;
    padding: 0;
    width: 100%;
}

.item {
    width: 100%;
}

.search {
    display: flex;
    align-items: center;
    min-height: 50px;
    width: 100%;
    padding: 0 25px;
}

.category {
    @include button;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    min-height: 50px;
    padding: 1px 15px 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;

    background: none;
    text-decoration: none;
    font-size: 1.3125rem;
    font-weight: 300;
}

.links {
    max-height: 0;
    overflow: hidden;

    display: block;
    transition: all 200ms ease-out;

    &.open {
        max-height: unset;
        border-bottom: 1px solid;
    }
}

.linkWrapper {
    width: 100%;
    height: 50px;
    padding: 1px 30px 0;

    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.link {
    width: 100%;
    height: 49px;

    display: flex;
    align-items: center;

    font-size: rem(18px);
    font-weight: 300;
    text-decoration: none;
}

.icon.icon {
    width: 15px;
    height: 15px;
}

.zoznamIcon.zoznamIcon {
    height: 30px;
    width: 100px;
}

.zoznamLink {
    @include link;

    width: 100%;
    min-height: 50px;
    padding: 0 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: static;

    &:hover {
        text-decoration: none;
    }

    @media #{$sm} {
        display: none;
    }
}

@include theme-garden {
    .navigationMenu {
        background-color: $white;
    }

    .category {
        border-bottom: 1px solid $green050;

        color: $green900;

        &:hover {
            background-color: $grey100;
            cursor: pointer;
        }

        &.highlight {
            color: $red600;
        }
    }

    .icon {
        color: $green900;
    }

    .link {
        color: $green900;
    }

    .links {
        border-bottom-color: $green050;
    }
}

@include theme-living {
    .navigationMenu {
        background-color: $white;
    }

    .category {
        border-bottom: 1px solid $grey100;

        color: $blue700;

        &:hover {
            background-color: $grey100;
            cursor: pointer;
        }
    }

    .icon {
        color: $blue700;
    }

    .link {
        color: $blue700;
    }

    .links {
        border-bottom-color: $grey100;
    }
}

@include theme-wedding {
    .navigationMenu {
        background-color: $white;
    }

    .category {
        border-bottom: 1px solid $pink100;

        color: $grey700;

        &:hover {
            background-color: $pink100;
            cursor: pointer;
        }
    }

    .icon {
        color: $grey700;
    }

    .link {
        color: $grey700;
    }

    .links {
        border-bottom-color: $pink100;
    }

    .zoznamIcon.zoznamIcon {
        color: $pink200;
    }
}
