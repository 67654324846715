@import '~four-nets/sass/four-nets-mixins';

.form.form {
    width: 100%;
    padding: 0;

    @media #{$lg} {
        width: 100%;
        padding: 0;
    }
}
