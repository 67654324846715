@import '~four-nets/sass/four-nets-mixins';

.bodyLockScroll {
    overflow: hidden;
}

.container {
    position: relative;
    z-index: 0;
}

.overlay {
    z-index: 120;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba($black, 0.3);
}

.dialog {
    padding: 15px;

    border-radius: 5px;

    background-color: $grey50;

    position: absolute;

    @media #{$lg} {
        min-width: 300px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    font-weight: 600;
    font-size: rem(18px);

    color: $grey850;
}

.body {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$lg} {
        min-height: 70px;
    }
}

.footer {
    margin-top: 15px;

    display: flex;
    justify-content: flex-end;
}

.footerButton {
    margin-right: 10px;
    padding: 10px 16px;
    border: 0;
    border-radius: 8px;

    font-size: rem(14px);
    font-weight: 600;

    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
}

@include theme-living {
    .body {
        color: $grey470;
    }

    .footerButton {
        background-color: $blue700;
        color: $white;

        &.warn {
            background-color: $red400;
            color: $white;
        }

        &.action {
            background-color: $red600;
            color: $white;
        }

        &.cancel {
            color: $grey850;
            border: 1px solid $grey430;
            background-color: transparent;
        }
    }
}

@include theme-garden {
    .body {
        color: $grey470;
    }

    .footerButton {
        background-color: $green900;
        color: $white;

        &.warn {
            background-color: $red400;
            color: $white;
        }

        &.action {
            background-color: $red600;
            color: $white;
        }

        &.cancel {
            color: $grey850;
            border: 1px solid $grey430;
            background-color: transparent;
        }
    }
}

@include theme-wedding {
    .body {
        color: $grey470;
    }

    .footerButton {
        background-color: $pink900;
        color: $white;

        &.warn {
            background-color: $red400;
            color: $white;
        }

        &.action {
            background-color: $red600;
            color: $white;
        }

        &.cancel {
            color: $grey850;
            border: 1px solid $grey430;
            background-color: transparent;
        }
    }
}
