@import '~four-nets/sass/four-nets-mixins';

.form {
    > :nth-child(1) {
        margin-bottom: 10px;
    }

    > :nth-child(3) {
        margin-bottom: 20px;
    }

    > :nth-last-child(1) {
        margin-top: 20px;
    }
}

.passwordResetWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.passwordResetButton {
    background: none;
    border: none;
    font-size: 0.875rem;
    font-weight: 300;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.modal.modal {
    min-height: 476px !important;
}
