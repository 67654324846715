body {
    /* Fonts */
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;

    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

* {
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}

/*h1, h2, h3, h4, h5, h6 {
    margin: 0;
}*/

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: inherit;
  font-family: inherit;
}

:global(.no-scrollbar)::-webkit-scrollbar {
    display: none;
}

::-webkit-file-upload-button {
    cursor: pointer;
}

/* https://msdn.microsoft.com/en-us/library/windows/apps/hh465740.aspx */
::-ms-clear { width : 0; height: 0; } 
::-ms-reveal { width : 0; height: 0; }

