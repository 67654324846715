@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.wrapper {
    cursor: pointer;
}

.checkboxInput {
    position: relative;
}

.input {
    width: 14px;
    height: 14px;

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    cursor: pointer;
}

.icon.icon {
    width: 15px;
    height: 15px;
}

.label {
    position: relative;
    font-size: 0.875rem;
    font-weight: 300;
    padding-left: 5px;
}

.error {
    margin: 0;
    font-size: small;
    color: $red600;
    font-weight: 300;
    padding: 5px 0 0;

    &:last-of-type {
        padding: 5px 0 10px;
    }
}

@include theme-garden {
    .icon.icon {
        color: $green900;
    }
    .label {
        color: $grey700;
    }
}

@include theme-living {
    .icon.icon {
        color: $black;
    }
    .label {
        color: $black;
    }
}

@include theme-wedding {
    .icon.icon {
        color: $black;
    }
    .label {
        color: $black;
    }
}
