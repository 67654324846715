:global {
    @import './import_tailwind.scss';

    .living {
        --primary-100: #d9e3e7;
        --primary-200: #d2e3eb;
        --primary-300: #dde3e6;
        --primary-400: #607d8b;
        --primary-500: #00a1df;
        --primary-600: #0098d8;
        --primary-700: #003b71;
        --primary-800: #012d55;
        --primary-900: #222445;
        --primary-950: #002444;

        --living-primary-100: #d9e3e7;
        --living-primary-200: #d2e3eb;
        --living-primary-300: #dde3e6;
        --living-primary-400: #607d8b;
        --living-primary-500: #00a1df;
        --living-primary-600: #0098d8;
        --living-primary-700: #003b71;
        --living-primary-800: #012d55;
        --living-primary-900: #222445;
        --living-primary-950: #002444;

        --top-menu: #012d55;
        --top-menu-link-color: white;
        --top-menu-user-color: #002444;
        --bottom-menu-color: #003b71;
        --bottom-menu-link-color: #eceff1;
        --bottom-menu-link-underline-color: #546e7a;
    }

    .wedding {
        --primary-100: #f5f5f5;
        --primary-200: #eadfdf;
        --primary-300: #e1d6d6;
        --primary-400: #f1568b;
        --primary-500: #e93774;
        --primary-600: #bd134d;
        --primary-700: #a70f43;
        --primary-800: #8b0d38;
        --primary-900: #c12d5a;
        --primary-950: #b12852;

        --wedding-primary-100: #f5f5f5;
        --wedding-primary-200: #eadfdf;
        --wedding-primary-300: #e1d6d6;
        --wedding-primary-400: #f1568b;
        --wedding-primary-500: #e93774;
        --wedding-primary-600: #bd134d;
        --wedding-primary-700: #a70f43;
        --wedding-primary-800: #8b0d38;
        --wedding-primary-900: #c12d5a;
        --wedding-primary-950: #b12852;

        --top-menu: #1d8e8e;
        --top-menu-link-color: white;
        --top-menu-user-color: #177171;
        --bottom-menu-color: #eadfdf;
        --bottom-menu-link-color: #c12d5a;
        --bottom-menu-link-underline-color: #c12d5a;
    }

    .garden {
        --primary-100: #f5f5f5;
        --primary-200: #cae6c7;
        --primary-300: #7bc788;
        --primary-400: #7ad490;
        --primary-500: #03b953;
        --primary-600: #03b953;
        --primary-700: #014b22;
        --primary-800: #014217;
        --primary-900: #013317;
        --primary-950: #022913;

        --garden-primary-100: #f5f5f5;
        --garden-primary-200: #cae6c7;
        --garden-primary-300: #7bc788;
        --garden-primary-400: #7ad490;
        --garden-primary-500: #03b953;
        --garden-primary-600: #014b22;
        --garden-primary-700: #014217;
        --garden-primary-800: #013f1d;
        --garden-primary-900: #013317;
        --garden-primary-950: #022913;

        --top-menu: #d4dfdb;
        --top-menu-link-color: #014b22;
        --top-menu-user-color: #dce5e2;
        --bottom-menu-color: #ffffff;
        --bottom-menu-link-color: #014b22;
        --bottom-menu-link-underline-color: #03b953;
    }

    @-webkit-keyframes :global(ping) {
        75%,
        100% {
            -webkit-transform: scale(2);
            transform: scale(2);
            opacity: 0;
        }
    }

    @keyframes :global(ping) {
        75%,
        100% {
            -webkit-transform: scale(2);
            transform: scale(2);
            opacity: 0;
        }
    }
}
