@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';
@import '~../button-view';

@include theme-garden {
    .button {
        border: 0;

        &:not(.disabled) {
            background-color: $green500;
            color: $white;
            border: 0;

            &:hover {
                background-color: $green700;
                color: $white;
                border: 0;
            }
        }
    }
}

@include theme-living {
    .button {
        border: 0;

        &:not(.disabled) {
            background-color: $blue600;
            color: $white;
            border: 0;

            &:hover {
                background-color: $blue700;
                color: $white;
                border: 0;
            }
        }
    }
}

@include theme-wedding {
    .button {
        border: 0;

        &:not(.disabled) {
            background-color: $pink900;
            color: $pink100;
            border: 0;

            &:hover {
                background-color: $pink900;
                color: $pink100;
                border: 0;
            }
        }
    }
}
