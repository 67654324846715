@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';
@import '../mixins';

.icon {
    @include footer-image;

    display: inline-block;
    height: 48px;
    width: 48px;
}

@include theme-garden {
    .icon {
        color: $white;
    }
}

@include theme-living {
    .icon {
        color: $white;
    }
}

@include theme-wedding {
    .icon {
        color: $white;
    }
}
