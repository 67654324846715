@import '~four-nets/sass/four-nets-mixins';

.wrapper {
    margin: 0 auto;
    max-width: 374px;
}

.socialWrapper {
    margin: 20px 0;
}

.divider {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;

    div {
        z-index: 2;
        padding: 0 5px;
        font-size: 0.875rem;
        font-weight: 300;
    }

    &:after {
        content: '';
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-bottom: 1px solid;
    }
}

@include theme-living {
    .divider {
        div {
            background-color: $grey100;
        }
        &:after {
            border-bottom: 1px solid $grey300;
        }
    }
}

@include theme-garden {
    .divider {
        div {
            background-color: $grey100;
        }
        &:after {
            border-bottom: 1px solid $grey300;
        }
    }
}

@include theme-wedding {
    .divider {
        div {
            background-color: $pink100;
        }
        &:after {
            border-bottom: 1px solid $grey300;
        }
    }
}
