@import '~four-nets/sass/four-nets-mixins';

.navigation {
    height: 60px;
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

    @media #{$lg} {
        height: 70px;
    }
}

.navigationWrapper {
    margin: 0 auto;
    height: 100%;
    max-width: 1110px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.centerer {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.nav {
    width: 100%;
    margin-top: 5px;

    display: none;
    flex-direction: column;

    &.shown {
        display: flex;
    }

    position: absolute;

    @media #{$lg} {
        position: initial;
        display: flex;
        flex-direction: row;
        width: auto;
    }
}

.list {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
}

.listItem {
    position: relative;
    display: flex;
    width: auto;

    a {
        text-decoration: none;
    }
}

.newLabel {
    padding: 4px 8px;
    background-color: #0098d8;
    color: white;
    font-weight: bold;
    border-radius: 9999px;
    position: absolute;
    top: -15px;
    right: 14px;
    font-size: 11px;
}

.searchAndUser {
    display: flex;
    height: 100%;
}

.search {
    display: none;

    @media #{$lg} {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 10px;
    }
}

.userPanel {
    height: 100%;
    width: 100%;

    @media #{$lg} {
        display: flex;
        // width: 220px;
    }
}

.form.form {
    padding: 0;
    display: flex;
    align-items: center;
}

.icon.icon {
    width: 100px;
    height: 30px;
}

.zoznamLink {
    @include link;

    position: static;

    display: none;

    &:hover {
        text-decoration: none;
    }

    @media #{$sm} {
        display: block;
        margin-right: 10px;
    }

    @media #{$lg} {
        display: block;

        position: absolute;
        right: -120px;
    }
}

@include theme-garden {
    .navigation {
        background-color: $white;
    }

    .icon.icon {
        color: $white;
    }
}

@include theme-living {
    .navigation {
        background-color: $blue700;
    }

    .icon.icon {
        color: $white;
    }
}

@include theme-wedding {
    .navigation {
        background-color: $pink200;
    }

    .icon.icon {
        color: $white;
    }
}
