.image {
    opacity: 0;
    transition: opacity 100ms;
    display: inline-block;

    svg {
        width: inherit;
        height: inherit;
    }
}

.loaded {
    opacity: 1;
}

.cached {
    transition: none;
}
