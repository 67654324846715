@import '../mixins';
@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.link {
    min-width: 150px;
    @include footer-text;
}

@include theme-garden {
    .box {
        background-color: $green500;
    }

    .link {
        @include garden;
    }
}

@include theme-living {
    .link {
        @include living;
    }
}

@include theme-wedding {
    .link {
        @include wedding;
    }
}
