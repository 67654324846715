@import '~four-nets/sass/four-nets-mixins';

.attachment {
    width: 110px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    align-items: center;
    cursor: default;
    background-color: $white;
    font-weight: 300;
    border: 0;

    &.downloadable {
        cursor: pointer;
    }
}

.icon {
    width: 45px;
    height: 35px;
}

.label {
    display: block;
    text-align: center;
    padding: 5px 5px 10px;
    font-weight: 600;
    width: 100%;
    max-width: 120px;
    font-size: 0.875em;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
}

@include theme-garden {
    .atachment {
        color: $green900;
    }

    .icon {
        color: $red600;
    }

    .label {
        color: $green900;
    }
}

@include theme-living {
    .atachment {
        color: $blue700;
    }

    .icon {
        color: $red600;
    }

    .label {
        color: $blue700;
    }
}

@include theme-wedding {
    .atachment {
        color: $genoa;
    }

    .icon {
        color: $genoa;
    }

    .label {
        color: $genoa;
    }
}
