@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';
@import '~lib/sass/media';

@mixin big-button($color: $red600) {
    margin: 0;
    padding: 8px 14px;

    border: 1px solid;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: rem(16px);

    outline-style: none;
    outline-color: transparent;

    color: $grey400;
    background-color: $white;
    border-color: $grey400;

    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
    }

    &:not(:disabled) {
        background-color: $color;
        color: $white;
        border-color: $color;

        &:hover {
            color: $color;
            background-color: $white;
        }
    }

    @include theme-wedding {
        &:not(:disabled) {
            background-color: $color;
            color: $white;
            border-color: $color;

            &:hover {
                color: $color;
                background-color: $white;
            }
        }
    }
}
