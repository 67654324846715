@import '~four-nets/sass/four-nets-mixins';

.form {
    display: flex;
    max-width: 374px;
}

.wrapper.wrapper {
    margin-right: 10px;
}

.button.button {
    width: 120px;
    height: 40px;
}
