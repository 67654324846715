@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';
@import '~lib/sass/font-sizes';

.title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media #{$md} {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }
}

.components {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    @media #{$md} {
        width: auto;
        margin-top: 0;
    }
}

.text {
    @include title-font;
    @media #{$lg} {
        padding-top: 15px;
    }
}

.hasBothComponents {
    .components {
        justify-content: space-between;

        @media #{$md} {
            flex-basis: 45%;
        }
    }
}

@include theme-garden {
    .text {
        color: $green900;
    }
}

@include theme-living {
    .text {
        color: $blue700;
    }
}

@include theme-wedding {
    .text {
        color: $grey700;
    }
}
