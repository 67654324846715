@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.form {
    > :nth-child(1) {
        margin-top: 30px;
    }

    > :nth-last-child(1) {
        margin-top: 10px;
    }

    > div {
        margin-bottom: 10px;
    }
}

.checkboxes {
    > span {
        display: inline-block;
    }
}

.label.label {
    width: auto;
    min-width: 60px;
}

.field.field {
    width: 100%;
    min-width: initial;
}

.wrapper.wrapper {
    width: 100%;
}

.select.select {
    width: 100%;
}

.link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@include theme-living {
    .link {
        color: $purple600;
    }

    .select.select {
        background-color: $white;
    }
}
@include theme-garden {
    .link {
        color: $purple600;
    }

    .select.select {
        background-color: $white;
    }
}

@include theme-wedding {
    .link {
        color: $purple600;
    }

    .select.select {
        background-color: $white;
    }
}
