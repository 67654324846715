@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.partnerLink {
    text-decoration: none;
    font-size: 0.9rem;
    margin-right: 40px;
    font-weight: 400;
}

@include theme-garden {
    .partnerLink {
        color: $green900;
    }
}

@include theme-living {
    .partnerLink {
        color: $grey200;
    }
}

@include theme-wedding {
    .partnerLink {
        color: $white;
    }
}
