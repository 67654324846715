@mixin button {
    background: none;
    cursor: pointer;
    border: 0;
    margin: 0;
    text-decoration: none;

    &[disabled] {
        cursor: not-allowed;
    }
}
