@import '~four-nets/sass/four-nets-mixins';

.modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$lg} {
        width: 500px;
        height: 300px; 
    }
}

.footNote {
    font-size: rem(12px);
}

.period {
    border-bottom: 1px solid;
    padding-bottom: 5px;
}

.price {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 40px;

    font-weight: 600;
}

.spinnerWrapper {
    padding: 20px 0;
    display: flex;
    justify-content: center;
}
