@import '~four-nets/sass/four-nets-mixins';

.wrapper {
    position: relative;
    height: 0px;
    width: 100%;
}

.contentWrapper {
    width: 100%;
    position: absolute;
    z-index: 5;
}

.user {
    @include button;
    width: 100%;
    padding: 10px;
    border: 1px solid;
    border-top: none;

    display: flex;

    z-index: 5;
    cursor: pointer;
}

.avatar.avatar {
    width: 30px;
    height: 30px;
    border: 1px solid;
    margin-right: 10px;

    @media #{$lg} {
        width: 30px;
        height: 30px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    justify-items: space-between;
    align-items: flex-start;
}

.username {
    font-weight: 600;
    font-size: rem(14px);
}

.county {
    font-size: rem(13px);
}

.forceHide {
    border: 0;

    position: absolute;
    right: 5px;
    top: -27px;

    background: none;
}

.icon.icon {
    width: 15px;
    height: 15px;
}

@include theme-garden {
    .input {
        &.focused {
            border-color: $green600;
        }
    }

    .user {
        background-color: $white;
        border-color: $grey200;

        &.focused {
            background-color: $grey200;
        }

        &:hover {
            background-color: $grey200;
        }
    }

    .avatar.avatar {
        border-color: $grey300;
    }

    .county {
        color: $grey500;
    }
}

@include theme-living {
    .input {
        &.focused {
            border-color: $blue600;
        }
    }

    .user {
        background-color: $white;
        border-color: $grey200;

        &.focused {
            background-color: $grey200;
        }

        &:hover {
            background-color: $grey200;
        }
    }

    .avatar.avatar {
        border-color: $grey300;
    }

    .county {
        color: $grey500;
    }
}

@include theme-wedding {
    .input {
        &.focused {
            border-color: $genoa;
        }
    }

    .user {
        background-color: $white;
        border-color: $grey200;

        &.focused {
            background-color: $grey200;
        }

        &:hover {
            background-color: $grey200;
        }
    }

    .avatar.avatar {
        border-color: $grey300;
    }

    .county {
        color: $grey500;
    }
}
