@import '~four-nets/sass/four-nets-mixins';
@import '~lib/sass/photoblog-title';

.title {
    @include photoblog-title;
    margin-top: 10px;
    padding-bottom: 10px;

    @media #{$lg} {
        margin-top: 20px;
    }
}

.form {
    width: 100%;
    padding-bottom: 20px;

    @media #{$lg} {
        width: 650px;
    }
}

.label {
    margin-top: 10px;
    padding-bottom: 5px;

    display: inline-block;

    font-size: rem(16px);
    font-weight: 600;
}

.submitButton {
    @include big-button;
    width: 100%;
    margin-top: 10px;

    justify-content: center;
}

.submitIcon.submitIcon {
    width: 17px;
    height: 17px;
    margin-right: 10px;
}

.attachemntWrapper {
    width: 120px;
    height: 110px;

    padding: 0 5px;

    position: relative;
    display: inline-block;
}

.deleteAttachemnt {
    @include button;

    position: absolute;
    top: 0;
    right: 0;
}

.deleteIcon.deleteIcon {
    height: 10px;
    width: 10px;
}

.fileInput.fileInput {
    margin-top: 5px;
}

@include theme-garden {
    .title {
        color: $green900;
    }

    .error {
        color: $red600;
    }

    .submitButton {
        &:not(:disabled) {
            background-color: $green500;
            color: $white;
            border-color: $green600;

            &:hover {
                color: $white;
                background-color: $green700;
                border-color: $green700;
            }
        }
    }

    .attachment.attachment {
        background-color: transparent;
    }
}

@include theme-living {
    .title {
        color: $blue700;
    }

    .error {
        color: $red600;
    }

    .submitButton {
        &:not(:disabled) {
            background-color: $blue800;
            color: $white;
            border-color: $blue800;

            &:hover {
                color: $white;
                background-color: $blue600;
                border-color: $blue600;
            }
        }
    }

    .attachment.attachment {
        background-color: transparent;
    }
}

@include theme-wedding {
    .title {
        color: $genoa; // genoa title
    }

    .error {
        color: $red600;
    }

    .submitButton {
        &:not(:disabled) {
            background-color: $pink900;
            color: $white;
            border-color: $pink900;

            &:hover {
                color: $pink900;
                background-color: $white;
                border-color: $pink900;
            }
        }
    }

    .attachment.attachment {
        background-color: transparent;
    }
}
