.button {
    padding: 0;
    border: 0;
    background: 0;
    cursor: pointer;
}

.emoji {
    height: 25px;
    width: 25px;
    vertical-align: text-bottom;
}
