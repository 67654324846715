@import '~four-nets/sass/four-nets-mixins';

.inputWrapper {
    width: 100%;
    padding: 2px 10px 5px;
    border: 1px solid;

    border-radius: 5px;
    overflow: hidden;

    cursor: text;
}

.user {
    height: 30px;
    padding: 5px 10px;
    margin: 5px 5px 0 0;
    border: 0;
    border-radius: 2px;

    display: inline-block;
}

.usersInput {
    @include no-focus-outline;

    height: 30px;
    border-radius: 5px;
    padding: 0;
    border: 0;

    display: inline-block;
}

.deleteButton {
    @include no-focus-outline;

    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    display: inline-block;

    cursor: pointer;
}

.deleteIcon.deleteIcon {
    width: 15px;
    height: 11px;
    margin-left: 5px;
}

.spinner.spinner {
    height: 15px;
}

@include theme-garden {
    .user {
        background-color: $grey200;
    }

    .inputWrapper {
        background-color: $white;
        border-color: $grey300;

        &.focused {
            border-color: $green600;
        }

        &.errorBorder {
            border-color: $red600;
        }
    }

    .usersInput {
        color: $grey600;
    }
}

@include theme-living {
    .user {
        background-color: $grey200;
    }

    .inputWrapper {
        background-color: $white;
        border-color: $grey300;

        &.focused {
            border-color: $blue600;
        }

        &.errorBorder {
            border-color: $red600;
        }
    }

    .usersInput {
        color: $grey600;
    }
}

@include theme-wedding {
    .user {
        background-color: $grey200;
    }

    .inputWrapper {
        background-color: $white;
        border-color: $grey300;

        &.focused {
            border-color: $genoa;
        }

        &.errorBorder {
            border-color: $red600;
        }
    }

    .usersInput {
        color: $grey600;
    }
}
