@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.emojis {
    padding: 2px;
    display: inline-block;
}

.emojiSelector {
    opacity: 1;
    display: block;
}
