@import '~four-nets/sass/four-nets-mixins';

.textAreaWrapper {
    width: 100%;
    position: relative;
}

.textArea {
    @include no-focus-outline;

    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 190px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;

    line-height: 1.5;

    box-sizing: border-box;

    .emojione {
        width: 1rem;
        height: 1rem;
    }

    &.invalid {
        border-color: $red600;
    }

    :global(.emojione) {
        width: 20px;
        height: 20px;
    }
}

.error {
    margin: 0;
    font-size: 0.9rem;
    color: $red600;
    font-weight: 300;
    padding: 5px 0 0;

    &:last-of-type {
        padding: 5px 0 10px;
    }
}

.emojiAbsoluteWrapper {
    position: absolute;
    bottom: 20px;
    right: 20px;

    @media #{$lg} {
        right: 10px;
    }

    &.withErrors {
        bottom: 40px;

        @media #{$lg} {
            bottom: 50px;
        }
    }
}

.emojiRelativeWrapper {
    position: relative;
}

.emojiButton {
    margin-right: 8px;
    padding: 0;
    border: 0;

    background: transparent;
    cursor: pointer;
}

.iconSmile {
    width: 18px;
    height: 18px;
}

.emojiWindow.emojiWindow {
    top: 5px;
    right: 0;
}

.textareaDiv {
    width: 633px;

    position: absolute;
    padding: 10px;
    z-index: -1;

    white-space: pre-wrap;
    font-weight: 400;
    line-height: 1.5;

    visibility: hidden;
}

.label {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    position: absolute;

    overflow: hidden;

    opacity: 0;
    clip: rect(0 0 0 0);
}

@include theme-garden {
    .textArea {
        color: $grey600;
        border-color: $grey300;
        background-color: $white;

        &:focus {
            border-color: $green600;
        }

        &.invalid {
            &:focus {
                border-color: $red600;
            }
        }
    }

    .iconSmile {
        color: $grey500;
    }
}

@include theme-living {
    .textArea {
        color: $grey600;
        border-color: $grey300;
        background-color: $white;

        &:focus {
            border-color: $blue600;
        }

        &.invalid {
            &:focus {
                border-color: $red600;
            }
        }
    }

    .iconSmile {
        color: $grey500;
    }
}

@include theme-wedding {
    .textArea {
        color: $grey600;
        border-color: $grey400;
        background-color: $white;

        &:focus {
            border-color: $grey400;
        }

        &.invalid {
            &:focus {
                border-color: $pink900;
            }
        }
    }

    .iconSmile {
        color: $grey500;
    }
}
