@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.centered {
    width: 100%;
    padding: 40px 0;
}

.wrapper {
    padding: 10px;
    border: 1px solid $grey200;
    background-color: $grey100;
    display: flex;
    align-items: center;
}

.errorText {
    margin: 0;
    padding: 0 0 0 20px;
}

.errorMessage {
    font-size: 0.875rem;
}

.icon.icon {
    color: $grey300;
    width: 65px;
    height: 65px;
}

.text {
    width: 100%;
    text-align: left;
    font-size: 0.85rem;
    padding: 10px 0 0;
    margin: 0;

    @media not screen and #{$lg} {
        padding: 10px 0 0 20px;
    }
}

.link {
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
    color: $red600;
    font-weight: 600;
    text-decoration: none;
    text-transform: lowercase;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
