.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.\!visible {
  visibility: visible !important
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.-right-12 {
  right: -3rem
}

.-right-8 {
  right: -2rem
}

.-top-3 {
  top: -0.75rem
}

.bottom-0 {
  bottom: 0px
}

.bottom-1 {
  bottom: 0.25rem
}

.left-0 {
  left: 0px
}

.right-0 {
  right: 0px
}

.top-10 {
  top: 2.5rem
}

.z-50 {
  z-index: 50
}

.col-start-2 {
  grid-column-start: 2
}

.row-span-2 {
  grid-row: span 2 / span 2
}

.row-span-3 {
  grid-row: span 3 / span 3
}

.m-0 {
  margin: 0px
}

.m-6 {
  margin: 1.5rem
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem
}

.-mb-4 {
  margin-bottom: -1rem
}

.-mt-1 {
  margin-top: -0.25rem
}

.-mt-10 {
  margin-top: -2.5rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mr-0 {
  margin-right: 0px
}

.mr-0\.5 {
  margin-right: 0.125rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mr-4 {
  margin-right: 1rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-16 {
  margin-top: 4rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-4 {
  margin-top: 1rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-7 {
  margin-top: 1.75rem
}

.mt-8 {
  margin-top: 2rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-flex;
  display: flex
}

.table {
  display: table
}

.table-cell {
  display: table-cell
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-10 {
  height: 2.5rem
}

.h-36 {
  height: 9rem
}

.h-4 {
  height: 1rem
}

.h-48 {
  height: 12rem
}

.h-5 {
  height: 1.25rem
}

.max-h-screen {
  max-height: 100vh
}

.w-10 {
  width: 2.5rem
}

.w-32 {
  width: 8rem
}

.w-36 {
  width: 9rem
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-\[200px\] {
  width: 200px
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.w-full {
  width: 100%
}

.w-min {
  width: -webkit-min-content;
  width: min-content
}

.shrink {
  -webkit-flex-shrink: 1;
          flex-shrink: 1
}

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1
}

.flex-grow-0 {
  -webkit-flex-grow: 0;
          flex-grow: 0
}

.-rotate-45 {
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-move {
  cursor: move
}

.cursor-pointer {
  cursor: pointer
}

.select-none {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.resize {
  resize: both
}

.list-none {
  list-style-type: none
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr))
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.gap-1 {
  gap: 0.25rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.place-self-center {
  place-self: center
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-none {
  border-radius: 0px
}

.rounded-sm {
  border-radius: 0.125rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-b {
  border-bottom-width: 1px
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-l-0 {
  border-left-width: 0px
}

.border-r-0 {
  border-right-width: 0px
}

.border-t-0 {
  border-top-width: 0px
}

.border-solid {
  border-style: solid
}

.border-none {
  border-style: none
}

.border-gray-200 {
  border-color: #e5e7eb
}

.border-gray-300 {
  border-color: #d1d5db
}

.border-gray-500 {
  border-color: #6b7280
}

.border-pink-100 {
  border-color: #fce7f3
}

.bg-gray-100 {
  background-color: #f3f4f6
}

.bg-green-300 {
  background-color: #86efac
}

.bg-primary-300 {
  background-color: var(--primary-300)
}

.bg-primary-600 {
  background-color: var(--primary-600)
}

.bg-primary-800 {
  background-color: var(--primary-800)
}

.bg-red-900 {
  background-color: #7f1d1d
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  background-color: #fff
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops))
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops))
}

.from-gray-800\/0 {
  --tw-gradient-from: rgb(31 41 55 / 0);
  --tw-gradient-to: rgb(31 41 55 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-to: rgb(99 102 241 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-pink-700 {
  --tw-gradient-from: #be185d;
  --tw-gradient-to: rgb(190 24 93 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.via-gray-800\/80 {
  --tw-gradient-to: rgb(31 41 55 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.8), var(--tw-gradient-to)
}

.to-blue-400 {
  --tw-gradient-to: #60a5fa
}

.to-gray-800\/80 {
  --tw-gradient-to: rgb(31 41 55 / 0.8)
}

.to-pink-300 {
  --tw-gradient-to: #f9a8d4
}

.bg-cover {
  background-size: cover
}

.object-cover {
  object-fit: cover
}

.object-center {
  object-position: center
}

.p-0 {
  padding: 0px
}

.p-1 {
  padding: 0.25rem
}

.p-10 {
  padding: 2.5rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pt-5 {
  padding-top: 1.25rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.font-black {
  font-weight: 900
}

.font-bold {
  font-weight: 700
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.italic {
  font-style: italic
}

.ordinal {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction)
}

.text-\[\#1d8e8e\] {
  color: #1d8e8e
}

.text-\[\#222425\] {
  color: #222425
}

.text-gray-500 {
  color: #6b7280
}

.text-gray-600 {
  color: #4b5563
}

.text-gray-700 {
  color: #374151
}

.text-gray-800 {
  color: #1f2937
}

.text-gray-900 {
  color: #111827
}

.text-pink-700 {
  color: #be185d
}

.text-primary-700 {
  color: var(--primary-700)
}

.text-red-500 {
  color: #ef4444
}

.text-white {
  color: #fff
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.blur {
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-300 {
  transition-duration: 300ms
}

.duration-500 {
  transition-duration: 500ms
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.placeholder\:text-sm::-webkit-input-placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.placeholder\:text-sm:-ms-input-placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.placeholder\:text-sm::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.placeholder\:text-gray-400::-webkit-input-placeholder {
  color: #9ca3af
}

.placeholder\:text-gray-400:-ms-input-placeholder {
  color: #9ca3af
}

.placeholder\:text-gray-400::placeholder {
  color: #9ca3af
}

.placeholder\:text-gray-500::-webkit-input-placeholder {
  color: #6b7280
}

.placeholder\:text-gray-500:-ms-input-placeholder {
  color: #6b7280
}

.placeholder\:text-gray-500::placeholder {
  color: #6b7280
}

.placeholder\:text-gray-600::-webkit-input-placeholder {
  color: #4b5563
}

.placeholder\:text-gray-600:-ms-input-placeholder {
  color: #4b5563
}

.placeholder\:text-gray-600::placeholder {
  color: #4b5563
}

.placeholder\:text-white::-webkit-input-placeholder {
  color: #fff
}

.placeholder\:text-white:-ms-input-placeholder {
  color: #fff
}

.placeholder\:text-white::placeholder {
  color: #fff
}

.placeholder\:no-underline::-webkit-input-placeholder {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.placeholder\:no-underline:-ms-input-placeholder {
  text-decoration-line: none
}

.placeholder\:no-underline::placeholder {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.last\:border-b-0:last-child {
  border-bottom-width: 0px
}

.hover\:bg-\[\#e5e5e5\]:hover {
  background-color: #e5e5e5
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6
}

.hover\:bg-gray-200:hover {
  background-color: #e5e7eb
}

.hover\:bg-gray-50:hover {
  background-color: #f9fafb
}

.hover\:bg-primary-500:hover {
  background-color: var(--primary-500)
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.focus\:border-pink-400:focus {
  border-color: #f472b6
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.group:focus-within .group-focus-within\:bg-\[\#1d8e8e\] {
  background-color: #1d8e8e
}

.group:focus-within .group-focus-within\:bg-primary-500 {
  background-color: var(--primary-500)
}

.group:focus-within .group-focus-within\:bg-primary-600 {
  background-color: var(--primary-600)
}

.group:focus-within .group-focus-within\:text-gray-100 {
  color: #f3f4f6
}

.group:focus-within .group-focus-within\:text-gray-700 {
  color: #374151
}

.group:focus-within .group-focus-within\:text-white {
  color: #fff
}

.group:focus-within .group-focus-within\:placeholder\:text-gray-300::-webkit-input-placeholder {
  color: #d1d5db
}

.group:focus-within .group-focus-within\:placeholder\:text-gray-300:-ms-input-placeholder {
  color: #d1d5db
}

.group:focus-within .group-focus-within\:placeholder\:text-gray-300::placeholder {
  color: #d1d5db
}

.group:focus-within .group-focus-within\:placeholder\:text-gray-700::-webkit-input-placeholder {
  color: #374151
}

.group:focus-within .group-focus-within\:placeholder\:text-gray-700:-ms-input-placeholder {
  color: #374151
}

.group:focus-within .group-focus-within\:placeholder\:text-gray-700::placeholder {
  color: #374151
}

@media (min-width: 640px) {
  .sm\:flex {
    display: -webkit-flex;
    display: flex
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:gap-3 {
    gap: 0.75rem
  }
}

@media (min-width: 768px) {
  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem
  }
}

@media (min-width: 1024px) {
  .lg\:hidden {
    display: none
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }
}
