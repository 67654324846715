@import '~four-nets/sass/four-nets-mixins';

.row {
    width: 225px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 50px;
    padding-bottom: 25px;
    margin: 0;
}

.mobileOnly {
    @media #{$lg} {
        display: none;
    }
}

.title {
    margin: 0 0 10px;

    font-size: rem(18px);
    line-height: rem(22px);
    font-weight: 800;

    @media #{$lg} {
        margin: 0 0 25px;
    }
}

.children {
    width: 225px;
    padding: 0;
}

.child {
    margin: 0 10px 5px 0;

    display: inline-block;
    position: relative;

    line-height: 1.2;
}

@include theme-garden {
    .title {
        color: $grey700;
    }
}

@include theme-living {
    .title {
        color: $blue700;
    }
}

@include theme-wedding {
    .title {
        color: $pink900;
    }
}
