@import '~four-nets/sass/four-nets-mixins';

.loggedInStatus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.avatar.avatar {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;

    @media #{$lg} {
        width: 20px;
        height: 20px;
    }
}

.username {
    margin: 0;
    text-decoration: none;
}

.pro {
    margin-left: 5px;
    padding: 1.5px 5px;
    border-radius: 3px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: rem(10px);
    font-weight: 800;
    text-transform: uppercase;
}

@include theme-living {
    .loggedInStatus {
        background-color: $blue1000;
        color: $white;
    }
    .username {
        color: $white;
    }

    .pro {
        color: $white;
        background-color: $blue400;
    }
}

@include theme-garden {
    .loggedInStatus {
        background-color: $green200;
    }

    .username {
        color: $grey700;
    }

    .pro {
        color: $white;
        background-color: $green600;
    }
}

@include theme-wedding {
    .loggedInStatus {
        background-color: $genoa;
        color: $white;
    }
    .username {
        color: $white;
    }

    .pro {
        color: $white;
        background-color: $blue400;
    }
}
