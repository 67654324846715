@import '~four-nets/sass/four-nets-mixins';

.expired {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: rem(16px);
    font-weight: 600;
}

.expiredClose {
    @include button;

    position: absolute;
    top: 5px;
    right: 5px;
}

.expiredCloseIcon.expiredCloseIcon {

    height: 10px;
    width: 10px;
}

.expiredText {
    margin-right: 10px;
}

.expiredLink {
    @include button;

    padding: 5px 10px;
    border-radius: 5px;

    font-size: rem(12px);
    font-weight: 800;
    text-decoration: none;
    text-transform: uppercase;
}

@include theme-living {
    .link {
        background-color: $grey100;
        color: $blue800;
    }

    .expired {
        background-color: rgba($red600, 0.1);
        color: $red600;
    }

    .expiredLink {
        background-color: $red600;
        color: $white;
    }
}

@include theme-garden {
    .link {
        background-color: $grey100;
        color: $grey600;
    }

    .epxired {
        background-color: rgba($red600, 0.1);
        color: $red600;
    }

    .expiredLink {
        background-color: $red600;
        color: $white;
    }
}

@include theme-wedding {
    .link {
        background-color: $pink100;
        color: $grey700;
    }

    .expired {
        background-color: rgba($pink900, 0.1);
        color: $red600;
    }

    .expiredLink {
        background-color: $pink900;
        color: $white;
    }
}
