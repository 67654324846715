.badgeWrapper {
    position: relative;
    display: block;
}

.badge {
    position: absolute;
    display: block;
    bottom: 4px;
    right: 7px;
}
