@import '~four-nets/sass/four-nets-mixins';

.wrapper {
    height: 40px;

    position: relative;

    display: inline-block;
}

.icon {
    height: 100%;
    width: 100%;
    padding-right: 15px;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    pointer-events: none;
}

.select {
    height: 40px;
    padding: 10px 35px 10px 15px;

    border: 1px solid $grey200;
    border-radius: 3px;

    font-size: rem(14px);

    appearance: none;
}

.wrapper,
.select {
    width: 100%;
    @media #{$lg} {
        width: auto;
    }
}

.image.image {
    width: 12px;
    height: auto;
}

.error {
    padding: 5px 0 0;
    margin: 0;

    font-size: small;
    font-weight: 300;

    &:last-of-type {
        padding: 5px 0 10px;
    }
}

@include theme-garden {
    .wrapper {
        color: $grey600;
    }

    .select {
        background-color: $grey100;
    }

    .error {
        color: $red600;
    }
}

@include theme-living {
    .wrapper {
        color: $grey600;
    }

    .select {
        background-color: $grey100;
    }

    .error {
        color: $red600;
    }
}

@include theme-wedding {
    .wrapper {
        color: $grey600;
    }

    .select {
        background-color: $pink100;
    }

    .error {
        color: $red600;
    }
}
