@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

@import '~lib/sass/big-button';
@import '~lib/sass/button';
@import '~lib/sass/no-focus-outline';

.emojiWindow {
    width: 180px;
    margin-top: -4px;

    display: flex;
    flex-flow: column;
    align-items: flex-end;
    z-index: 2;
    position: absolute;
    right: -2px;
    top: 25px;
}

.emojiArrow {
    margin-right: 12px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid;
}

.emojiSelectorDiv {
    // Needs to be higher than post visibility selector
    // on mobile
    z-index: 1;
    border: 1px solid;
}

@include theme-garden {
    .emojiArrow {
        border-bottom-color: $grey200;
    }

    .emojiSelectorDiv {
        background-color: $white;
        border-color: $grey200;
    }
}

@include theme-living {
    .emojiArrow {
        border-bottom-color: $grey200;
    }

    .emojiSelectorDiv {
        background-color: $white;
        border-color: $grey200;
    }
}

@include theme-wedding {
    .emojiArrow {
        border-bottom-color: $grey200;
    }

    .emojiSelectorDiv {
        background-color: $white;
        border-color: $grey200;
    }
}
