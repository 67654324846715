@import '~four-nets/sass/four-nets-mixins';

.bodyLockScroll {
    overflow: hidden;
}

.container {
    &:not(:empty) {
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }
}

.overlay {
    display: none;

    &.open {
        z-index: 100;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba($black, 0.3);
    }
}

.modal {
    width: 98%;
    width: calc(100% - 20px);
    max-height: 80vh;
    padding: 10px;
    border-radius: 5px;

    background-color: $grey50;

    position: absolute;

    overflow: visible;

    @media #{$lg} {
        width: 700px;
        max-height: 90vh;
        padding: 20px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    font-weight: 600;
    font-size: rem(18px);

    color: $grey850;
}

.closeButton {
    border: 0;
    background: none;

    cursor: pointer;
}

.closeIcon {
    width: 15px;
    height: 15px;

    color: $grey470;
}

.body {
    width: 100%;
    max-height: 80%; // fallback
    max-height: calc(80vh - 150px);

    overflow-x: hidden;
    overflow-y: auto;

    @media #{$lg} {
        max-height: calc(90vh - 200px);
    }

    &:not(:first-child) {
        margin-top: 20px;
    }
}

.footer {
    margin-top: 15px;

    display: flex;
    justify-content: flex-end;
}

.footerButton {
    margin-right: 10px;
    border: 0;
    padding: 10px 16px;
    border-radius: 8px;

    font-size: rem(14px);
    font-weight: 600;
    white-space: nowrap;

    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

@include theme-living {
    .footerButton {
        background-color: $blue700;
        color: $white;

        &.action {
            background-color: $red600;
            color: $white;
        }

        &.warn {
            background-color: $red400;
            color: $white;
        }

        &.cancel {
            color: $grey850;
            border: 1px solid $grey430;
            background-color: transparent;
        }

        &:disabled {
            background-color: $grey430;
            color: $white;
        }
    }
}

@include theme-garden {
    .footerButton {
        background-color: $green900;
        color: $white;

        &.action {
            background-color: $red600;
            color: $white;
        }

        &.warn {
            background-color: $red400;
            color: $white;
        }

        &.cancel {
            color: $grey850;
            border: 1px solid $grey430;
            background-color: transparent;
        }

        &:disabled {
            background-color: $grey430;
            color: $white;
        }
    }
}

@include theme-wedding {
    .footerButton {
        background-color: $pink900;
        color: $white;

        &.action {
            background-color: $red600;
            color: $white;
        }

        &.warn {
            background-color: $red400;
            color: $white;
        }

        &.cancel {
            color: $grey850;
            border: 1px solid $grey430;
            background-color: transparent;
        }

        &:disabled {
            background-color: $grey430;
            color: $white;
        }
    }
}
