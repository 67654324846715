@import '~lib/sass/media';

.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: top 182ms ease-in;
    z-index: 2;

    @media #{$lg} {
        &.scrolled {
            top: -40px;
        }
    }
}

.navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
}
