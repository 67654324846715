.column {
    width: 225px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.rows {
    padding: 0;
}
