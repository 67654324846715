@import '~lib/sass/img/object-fit';
@import '~four-nets/sass/colors';

$transition-length: 0.2s;

.image {
    position: relative;
    display: block;
    z-index: 0;

    &.background::before {
        background-color: #f2f2f2;
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity $transition-length linear;
        // opacity: 1;
        z-index: 0;
    }

    // &.background.loaded::before {
    //     opacity: 0;
    // }

    &.loaded::before {
        background-color: transparent;
    }

    &.loaded {
        .img {
            opacity: 1;
        }

        // .placeholder {
        //     opacity: 0;
        // }
    }

    &.aspectRatio {
        overflow: hidden;
        width: 100%;

        .img,
        .placeholder {
            position: absolute;
            top: 0;
            left: 0;
        }

        &:not(.aspectFill):not(.containSpan) {
            .img,
            .placeholder {
                width: auto;
                height: auto;
            }

            &.portrait {
                .img,
                .placeholder {
                    height: 100%;
                }
            }

            &.landscape {
                .img,
                .placeholder {
                    width: 100%;
                }
            }
        }
    }

    &.aspectFill {
        width: 100%;
        overflow: hidden;

        &:not(.aspectRatio) {
            height: 100%;
        }

        .img,
        .placeholder {
            @include object-fit(cover);

            width: 100%;
            height: 100%;
        }
    }

    &.fill {
        .img,
        .placeholder {
            width: 100%;
        }
    }
}

.img {
    transition: opacity $transition-length linear;
    opacity: 0;
    position: relative;
    z-index: 0;

    &.contain {
        width: 100%;
        height: inherit;

        @include object-fit(contain);
    }

    &.scaleDown {
        @include object-fit(scale-down);
    }
}

.layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.placeholder {
    composes: layer;
    filter: blur(2rem);
    transform: scale(1);
    z-index: 0;
}

.block {
    display: block;
}

.overlay {
    composes: layer;
    composes: block;
    z-index: 0;
}

.aspect {
    composes: block;
}

.orientation-2 {
    transform: rotateY(180deg);
}

.orientation-3 {
    transform: rotate(180deg);
}

.orientation-4 {
    transform: rotate(180deg) rotateY(180deg);
}

.orientation-5 {
    transform: rotate(270deg) rotateY(180deg);
}

.orientation-6 {
    transform: rotate(90deg);
}

.orientation-7 {
    transform: rotate(90deg) rotateY(180deg);
}

.orientation-8 {
    transform: rotate(270deg);
}
