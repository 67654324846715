@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.centered {
    width: 100%;
    padding: 40px 0;
}

.wrapper {
    width: 95%;
    padding: 20px;
    margin: auto;

    border: 1px solid $grey200;
    background-color: $white;
    display: flex;
    align-items: center;
}

.lockIcon.lockIcon {
    color: $grey300;
    width: 65px;
    height: 65px;
}

.login {
    padding-left: 20px;
}

.loginText {
    margin: 0;
    padding: 0 0 10px;
}

.register {
    width: 95%;
    margin: auto;
    padding: 10px 0 0;

    text-align: left;
    font-size: 0.85rem;

    @media not screen and #{$lg} {
        padding: 10px 0 0 20px;
    }
}

.registerButton {
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
    color: $red600;
    font-weight: 600;
    text-transform: lowercase;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.button {
    display: flex;
}
