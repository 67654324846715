@import '~drop-shadow-converter/scss/mathfunctions';
@import '~drop-shadow-converter/scss/drop-shadow-converter';

$black: #000;
$white: #fff;
$yellow: yellow;

$grey50: #fbfbfb;
$grey100: #eceff1;
$grey150: #d8d8d8;
$grey200: #cfd8dc;
$grey250: #eeeeee;
$grey300: #b0bec5;
$grey350: #f0f0f0;
$grey400: #d2d9df;
$grey430: #e5e5e5;
$grey450: #818b91;
$grey470: #8e8e8e;
$grey500: #546e7a;
$grey600: #464947;
$grey700: #263238;
$grey800: #494646;
$grey850: #323232;
$grey900: #222425;

$red400: #f44336;
$red600: #d91b5c;
$purple600: #d30072;

$blue100: #d9e3e7;
$blue200: #d2e3eb;
$blue300: #dde3e6;
$blue400: #607d8b;
$blue500: #00a1df;
$blue600: #0098d8;
$blue700: #003b71;
$blue800: #012d55;
$blue900: #222445;
$blue1000: #002444;

$green050: #edf0ef;
$green100: #d4dfdb;
$green200: #dce5e2;
$green500: #03b953;
$green600: #70b84c;
$green700: #027a37;
$green900: #014b22;

$pink100: #f5f5f5;
$pink200: #eadfdf;
$pink300: #e1d6d6;
$pink900: #c12d5a;
$pink1000: #b12852;

// Bellow colors are named using
// https://chir.ag/projects/name-that-color/

$elm: #1d8e8e;
$genoa: #177171;
$spectra: #2c4f4f;
$eden: #0e4851;

$facebook: #1877f2;
$instagram: #177171;

.shadow {
    box-shadow: drop-shadow-converter($black, $angle: 90, $distance: 5px, $spread: 0, $size: 5px, $opacity: 10%);
}

@mixin drop-shadow {
    box-shadow: drop-shadow-converter($black, $angle: 90, $distance: 5px, $spread: 0, $size: 5px, $opacity: 10%);
}
