.image {
    opacity: 0;
    transition: opacity 100ms;
    display: inline-block;

    &__loaded {
        opacity: 1;
    }

    &__cached {
        transition: none;
    }

    svg {
        width: inherit;
        height: inherit;
    }
}

