@import '~four-nets/sass/four-nets-mixins';

.partnersAndLogin {
    height: 40px;
    width: 100%;
    display: none;

    @media #{$lg} {
        display: initial;
    }
}

.login {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 1110px;

    > div {
        position: absolute;
        top: 0;
        right: 1px;
        width: 221px;
        height: 100%;
    }
}

.expiredLink {
    @include button;

    padding: 5px 10px;
    border-radius: 5px;

    position: absolute;
    right: 245px;

    font-size: rem(12px);
    font-weight: 800;
    text-decoration: none;
    text-transform: uppercase;
}

.contactUs,
.vendorLink,
.midot {
    margin-right: 10px;

    font-size: rem(15px);
    text-decoration: none;
    font-weight: 400;
}

.vendorLink {
    font-weight: 600;
}

.midot {
    font-size: rem(32px);
    font-weight: 600;
}

@include theme-garden {
    .partnersAndLogin {
        background-color: $green100;
        color: $green900;
    }

    .expiredLink {
        background-color: $red600;
        color: $white;
    }

    .midot,
    .vendorLink,
    .contactUs {
        color: $green900;
    }
}

@include theme-living {
    .partnersAndLogin {
        background-color: $blue800;
    }

    .expiredLink {
        background-color: $red600;
        color: $white;
    }

    .midot,
    .vendorLink,
    .contactUs {
        color: $white;
    }
}

@include theme-wedding {
    .partnersAndLogin {
        background-color: $elm;
    }

    .expiredLink {
        background-color: $pink900;
        color: $white;
    }

    .midot,
    .vendorLink,
    .contactUs {
        color: $white;
    }
}
