.badge {
    overflow: hidden;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    transition: transform 350ms cubic-bezier(0.420, 0.000, 0.545, 1.650);
    background-color: #D91B5C;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 0.625em;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);

    &.visible {
        transform: scale(1);
    }
}
