@import '~four-nets/sass/theme';
@import '~four-nets/sass/colors';
@import '~lib/sass/media';
@import '~lib/sass/drop-shadow';

.userPanel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
}

.noUser {
    width: 100%;
    height: 100%;
    max-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.loginButton {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    border: 0;
    border-radius: 0;

    background: none;
    cursor: pointer;
}

.centerer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconHover,
.icon {
    @media #{$lg} {
        height: 30px;
        width: 30px;
    }
}

.iconHover {
    display: none;
}

.text {
    display: block;
    font-size: 0.725rem;
    font-weight: 400;
    width: 100%;
    max-width: 95px;
    min-width: 95px;
}

.background {
}

.mobile {
    display: block;

    @media #{$lg} {
        display: none;
    }
}

.menu {
    @include drop-shadow;

    background-color: $white;
}

.loginButton {
    &:hover {
        background-color: $grey100;

        .icon {
            display: none;
        }

        .iconHover {
            display: block;
        }
    }
}

@include theme-garden {
    .noUser {
        border-right: 1px solid $green100;
        border-left: 1px solid $green100;
    }

    .userPanel.hasUser {
        > div {
            &:first-child {
                border-left: 1px solid $green100;
            }

            border-right: 1px solid $green100;

            @media not screen and #{$md} {
                border-right: 0;
            }
        }
    }

    .loginButton {
        color: $green900;

        &:hover {
            background-color: $green200;
        }
    }
}

@include theme-living {
    .noUser {
        border-right: 1px solid $blue800;
        border-left: 1px solid $blue800;
    }

    .userPanel.hasUser {
        > div {
            &:first-child {
                border-left: 1px solid $blue800;
            }

            border-right: 1px solid $blue800;

            @media not screen and #{$md} {
                border-right: 0;
            }
        }
    }

    .loginButton {
        color: $white;

        &:hover {
            background-color: $white;
            color: $blue700;
        }
    }
}

@include theme-wedding {
    .noUser {
        border-right: 1px solid $pink900;
        border-left: 1px solid $pink900;
    }

    .userPanel.hasUser {
        > div {
            &:first-child {
                border-left: 1px solid $pink300;
            }

            border-right: 1px solid $pink300;

            @media not screen and #{$md} {
                border-right: 0;
            }
        }
    }

    .loginButton {
        color: $pink900;

        &:hover {
            background-color: $white;
            color: $pink900;
        }
    }
}
