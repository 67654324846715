.scroll {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    max-height: inherit;

    &.touch {
        -webkit-overflow-scrolling: touch;
    }
}
