@import '~four-nets/sass/four-nets-mixins';

.link {
    margin-right: 0;
    padding: 15px 25px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 30px;

    @media #{$lg} {
        margin-right: 20px;
        padding: 2px 0 0;
    }

    @media #{$xlg} {
        margin-right: 30px;
    }

    text-decoration: none;
    color: inherit;
    font-size: rem(18px);
    font-weight: 400;
}

@include theme-garden {
    .link {
        color: $green900;

        @media #{$lg} {
            margin-right: 15px;
        }

        @media #{$xlg} {
            margin-right: 20px;
        }

        &:hover {
            border-bottom: 2px solid $green600;
        }

        &__active {
            border-bottom: 2px solid $purple600;
        }
    }
}

@include theme-living {
    .link {
        color: $grey100;

        &:hover {
            border-bottom: 2px solid $grey500;
        }

        &__active {
            border-bottom: 2px solid $blue500;
        }
    }
}

@include theme-wedding {
    .link {
        @media #{$lg} {
            margin-right: 13px;
        }

        @media #{$xlg} {
            margin-right: 22px;
        }

        font-size: rem(16px);
        color: $pink900;

        &:hover {
            border-bottom: 2px solid $pink900;
        }

        &__active {
            border-bottom: 2px solid $pink900;
        }
    }
}
