@import '~four-nets/sass/colors';
@import '~lib/sass/media';
@import '~four-nets/sass/theme';

.mainWrapper {
    position: relative;

    background-color: $grey100;
}

.branding {
    z-index: 0;
    justify-content: center;
    width: 100%;
    padding-top: 70px;

    @media #{$lg} {
        position: fixed;
        padding-top: 120px;
    }
}

.brandingPlaceholder {
    display: none;

    @media #{$lg} {
        display: block;
        height: 270px;

        &.brandingEmpty {
            display: none;
        }
    }
}

.skyscraperWrapper {
    display: none;
    position: absolute;
    top: 20px;
    right: -160px;
    width: 160px;
    height: 100%;

    @media #{$lg} {
        display: block;
    }
}

.skyscraper {
    position: sticky;
    top: 80px;
}

.middleWrapper {
    justify-self: center;
}

.children {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 10px;

    &.brandingEmpty {
        padding-top: 0;
    }

    @media #{$lg} {
        padding-top: 0;
        &.brandingEmpty {
            padding-top: 110px;
        }

        max-width: 1050px;
    }

    @media #{$xlg} {
        max-width: 1150px;
    }
}

.noscript {
    z-index: 2;

    width: 100%;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;

    background: $grey100;
    border: 2px solid $red600;
}

.noscriptWarning {
    font-weight: 300;
    font-size: 1.3rem;

    color: $red600;
}

.childrenInnerWrapper {
    min-height: 500px;
    padding: 0 0 15px;

    background-color: $white;

    @media #{$lg} {
        min-height: 800px;
        padding: 20px 20px 15px;
    }
}

@include theme-living {
    .mainWrapper,
    .childrenInnerWrapper {
        background-color: $grey100;
    }
}

@include theme-garden {
    .mainWrapper,
    .childrenInnerWrapper {
        background-color: $green050;
    }
}

@include theme-wedding {
    .mainWrapper,
    .childrenInnerWrapper {
        background-color: $pink100;
    }
}
