@import '~four-nets/sass/four-nets-mixins';

.searchInput {
    width: 100%;
    padding: 2px;

    border-radius: 3px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.iconWrapper {
    min-height: 42px;
    min-width: 42px;

    margin-right: 10px;
    padding: 10px;
    border-radius: 3px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$lg} {
        min-height: 54px;
        min-width: 54px;
        margin-right: 20px;
    }
}

.icon.icon {
    width: 20px;
    height: 20px;
}

.input {
    width: 100%;
    height: 100%;

    box-sizing: border-box;
    border: 0;
    background-color: transparent;
    outline: none;

    font-weight: 400;
    font-size: 1rem;
}

.label {
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;

    position: absolute;
    overflow: hidden;
    opacity: 0;
    clip: rect(0 0 0 0);
}

@include theme-garden {
    .iconWrapper {
        color: $green900;
    }

    .input {
        color: $green900;
    }

    .searchInput {
        background-color: $white;
        border: 1px solid $grey300;

        &.focused {
            background-color: $white;
            border: 1px solid $green500;

            .iconWrapper {
                background-color: $green500;
                color: $white;
            }
        }
    }
}

@include theme-living {
    .iconWrapper {
        color: $blue600;
    }

    .input {
        color: $blue700;
    }

    .searchInput {
        background-color: $white;
        border: 1px solid $grey200;

        &.focused {
            background-color: $white;
            border: 1px solid $blue600;

            .iconWrapper {
                background-color: $blue600;
                color: $white;
            }
        }
    }
}

@include theme-wedding {
    .iconWrapper {
        color: $genoa;
    }

    .input {
        color: $grey900;
    }

    .searchInput {
        background-color: $white;
        border: 1px solid $grey200;

        &.focused {
            background-color: $white;
            border: 1px solid $genoa;

            .iconWrapper {
                background-color: $genoa;
                color: $white;
            }
        }
    }
}
