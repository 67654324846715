@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';
@import '~lib/loaders/spinkit/fading-circle-view';

$spinner-height: 40px !default;

.spinner {
    display: inline-block;
}

.fadingCircle {
    height: $spinner-height;
    width: $spinner-height;
    margin: 0;
}

.mini {
    .fadingCircle {
        height: $spinner-height / 2;
        width: $spinner-height / 2;
    }
}

@include theme-garden {
    .circle {
        &::before {
            background-color: $green500;
        }
    }
}

@include theme-living {
    .circle {
        &::before {
            background-color: $blue600;
        }
    }
}

@include theme-wedding {
    .circle {
        &::before {
            background-color: $grey700;
        }
    }
}
