@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

$loader-height: 40px;
$padding: 40px;

.loadingWrapper {
    position: relative;
    width: 100%;
}

.content {
    transition: opacity 300ms;
    width: 100%;
}

.centerer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    composes: centerer;
    padding: $padding 0;
    height: $loader-height;
    width: 100%;

    :global(.sk-fading-circle) {
        margin: 0;
    }
}

.error {
    composes: centerer;
    flex-direction: column;
    padding-bottom: 20px;
}

.errorMessage {
    padding: 10px;
    font-size: 0.9em;
    font-weight: 300;
}

.loadingWrapper.loading.hasContent {
    min-height: $loader-height + ($padding * 2);

    .content {
        opacity: 0.35;
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}

@include theme-garden {
    .loader {
        :global(.sk-circle) {
            &::before {
                background-color: $green500;
            }
        }
    }
}

@include theme-living {
    .loader {
        :global(.sk-circle) {
            &::before {
                background-color: $blue600;
            }
        }
    }
}

@include theme-wedding {
    .loader {
        :global(.sk-circle) {
            &::before {
                background-color: $grey700;
            }
        }
    }
}
