@import '~four-nets/sass/four-nets-mixins';

.button {
    display: inline-block;
    position: relative;
    user-select: none;
    overflow: hidden;
    text-align: center;
    height: 100%;
    border-radius: 3px;
    width: 100%;
    transition: all 100ms;
    padding: 0;
    margin: 0;
    font-size: 0.75rem;
    background: none;

    @media #{$md} {
        font-size: rem(18px);
    }
}

.layout {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.content {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: rem(16px);

    @media #{$sm} {
        padding: 6px 8px;
    }

    @media #{$md} {
        padding: 8px 14px;
    }
}

.icon {
    margin-right: 5px;

    @media #{$sm} {
        margin-right: 10px;
    }
}

.button {
    &:not(.disabled) {
        cursor: pointer;
    }

    &.disabled {
        color: rgba(0, 0, 0, 0.3);
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }
}

@include theme-garden {
    .button {
        &:not(.disabled) {
            border: 1px solid $green500;
            color: $green500;

            &:hover {
                color: $white;
                border: 1px solid $green500;
                background-color: $green500;
            }
        }
    }
}

@include theme-living {
    .button {
        &:not(.disabled) {
            border: 1px solid $blue600;
            color: $blue600;

            &:hover {
                color: $white;
                border: 1px solid $blue600;
                background-color: $blue600;
            }
        }
    }
}

@include theme-wedding {
    .button {
        &:not(.disabled) {
            border: 1px solid $pink900;
            color: $pink900;

            &:hover {
                color: $white;
                border: 1px solid $pink900;
                background-color: $pink900;
            }
        }
    }
}
