@import '~four-nets/sass/four-nets-mixins';

.tabs {
    width: 100%;
    padding: 0 10px;
    border-bottom: 1px solid;

    display: flex;
    flex-direction: row;

    @media #{$lg} {
        padding: 0;
    }
}
.button {
    @include button;

    height: 35px;
    border-bottom: 3px solid transparent;

    display: flex;
    text-align: center;

    padding: 5px 0;
    margin-right: 20px;

    text-transform: uppercase;
    font-weight: 800;
    white-space: nowrap;

    &:last-child {
        margin-right: 0;
    }
}

@include theme-garden {
    .tabs {
        background-color: transparent;
        border-bottom-color: $green100;
    }

    .button {
        color: $grey500;
        background-color: transparent;

        &.active,
        &:hover {
            color: $green900;
            border-bottom: 3px solid $green500;
        }
    }
}

@include theme-living {
    .tabs {
        background-color: transparent;
        border-bottom-color: $blue200;
    }

    .button {
        color: $blue400;
        background: transparent;

        &.active,
        &:hover {
            color: $blue900;
            border-bottom-color: $blue600;
        }
    }
}

@include theme-wedding {
    .tabs {
        background-color: transparent;
        border-bottom-color: $pink200;
    }

    .button {
        color: $blue400;
        background: transparent;

        &.active,
        &:hover {
            color: $pink900;
            border-bottom-color: $pink900;
        }
    }
}
