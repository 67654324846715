@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.fieldWithLabel {
    display: flex;
    flex-direction: column;

    &:not(.mini) {
        @media #{$lg} {
            flex-direction: row;
        }
    }
}

.label {
    height: auto;
    overflow: visible;
    display: flex;
    align-items: flex-end;
    margin-right: 0;
    font-weight: 600;
    font-size: 0.875rem;
    padding-bottom: 5px;

    @media #{$lg} {
        padding-bottom: 0;
        height: 40px;
        margin-right: 10px;
        align-items: center;
        min-width: 85px;
        width: 85px;
    }
}

.field {
    margin-right: 0;
    min-width: 220px;
    margin-top: 5px;

    @media #{$lg} {
        margin-right: 10px;
        min-width: 510px;
        margin-top: 0;

        &:last-child {
            margin-right: 0;
        }
    }
}

.fieldWithLabel {
    &.mini {
        .label {
            width: 100%;

            @media #{$lg} {
                margin-right: 0;
            }
        }

        .field {
            min-width: 0;
            margin-right: 0;
        }
    }

    &.vertical {
        flex-direction: row;

        .label {
            width: 85px;
        }

        .field {
            width: 100%;
        }
    }
}
