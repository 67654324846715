@import '~lib/sass/media';
@import '~four-nets/sass/theme';

.logo {
    text-decoration: none;
    color: transparent;
}

.image {
    margin: 0 10px;
    width: 80px;

    @media #{$lg} {
        margin: 0 50px 0 0;
        width: 140px;
    }
}

@include theme-living {
    .image {
        width: 60px;

        @media #{$lg} {
            width: 110px;
        }
    }
}

@include theme-garden {
    .image {
        @media #{$lg} {
            margin: 0 25px 0 0;
        }
    }
}

@include theme-wedding {
    .image {
        width: 95px;

        @media #{$ms} {
            width: 100px;
        }

        @media #{$sm} {
            width: 140px;
        }

        @media #{$lg} {
            margin: 0 20px 0 0;
            width: 120px;
        }
    }
}
