@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@include theme-garden {
    .link {
        color: $red600;
    }
}
