@import '~four-nets/sass/four-nets-mixins';

.margin {
    z-index: 1;

    width: 100%;
    min-height: 60px;
    max-height: 60px;
    height: 60px;

    position: relative;
}

.footer {
    z-index: 1;
    width: 100%;
    position: relative;
}

.columns {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 auto;
    padding: 25px 20px;

    @media #{$lg} {
        flex-direction: row;
        padding: 60px 0;
    }
}

.copyright {
    width: 100%;
    min-height: 50px;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: rem(14px);

    @media #{$lg} {
        min-height: 80px;
    }
}

.text {
    font-weight: 300;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    width: 100%;

    @media #{$lg} {
        padding: 0;
    }
}

.columns,
.text {
    max-width: 1110px;
}

@include theme-garden {
    .margin {
        background-color: $green050;
    }

    .footer {
        background-color: $green100;
    }

    .copyright {
        background-color: $white;
        color: $grey700;
    }
}

@include theme-living {
    .margin {
        background-color: $grey100;
    }

    .footer {
        background-color: $blue600;
    }

    .copyright {
        background-color: $blue700;
        color: $blue600;
    }
}

@include theme-wedding {
    .margin {
        background-color: $pink100;
    }

    .footer {
        background-color: $pink200;
    }

    .copyright {
        background-color: $elm;
        color: $white;
    }
}
