@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.wrapper {
    margin: 0 auto;
    max-width: 374px;
}

.fields {
    padding-bottom: 20px;
}

.link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@include theme-living {
    .link {
        color: $purple600;
    }
}

@include theme-wedding {
    .link {
        color: $purple600;
    }
}
