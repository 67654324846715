@import '../mixins';

.action {
    @include footer-text;

    border: 0;
    background: none;
    cursor: pointer;
}

.container {
    display: flex;
    align-items: center;
}

.mobileOnly {
    @media #{$lg} {
        display: none;
    }
}

@include theme-garden {
    .action {
        @include garden;
    }
}

@include theme-living {
    .action {
        @include living;
    }
}

@include theme-wedding {
    .action {
        @include wedding;
    }
}
