@import '~four-nets/sass/four-nets-mixins';

@mixin footer-text {
    text-decoration: none;
    display: inline-block;
    padding: 0 7px 0 0;

    font-size: rem(14px);
    line-height: rem(18px);

    &:hover {
        text-decoration: underline;
    }
}

@mixin footer-image {
    margin: 0 7px 0 0;
}

@mixin garden {
    color: $green900;

    &::before {
        color: transparent;
        background-color: transparent;
        // color: $green700;
        // background-color: $green700;
    }
}

@mixin living {
    color: $white;

    &::before {
        color: $blue600;
        background-color: $blue600;
    }
}

@mixin wedding {
    color: $pink900;

    &::before {
        color: $grey700;
        background-color: $grey700;
    }
}
