@import '~lib/sass/font-sizes';

@mixin photoblog-title {
    margin: 0;
    padding-bottom: 10px;
    padding-left: 10px;

    @media #{$lg} {
        padding-bottom: 20px;
        padding-left: 0;
    }

    @include title-font;
}
