@import '~lib/sass/media';
@import '~four-nets/sass/colors';
@import '~four-nets/sass/theme';

.userPanelButton {
    position: static;

    @media #{$lg} {
        position: relative;
    }

    &.active {
        border: 0;

        .menu {
            display: block;
        }
    }
}

.button {
    width: 50px;
    height: 60px;
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    border-radius: 0;

    @media #{$lg} {
        width: 73px;
        height: 70px;
    }
}

.icon {
    width: 23px;
    height: 23px;

    @media #{$lg} {
        width: 25px;
        height: 25px;
    }
}

.menu {
    display: none;
    max-width: 395px;
    width: 100%;
    top: 60px;
    position: absolute;
    right: 0;

    @media #{$md} {
        right: -1px;
    }

    @media #{$lg} {
        width: 395px;
        top: 70px;

        // &:nth-of-type(0) {
        //     right: -150px;
        // }
    }

    &:hover {
        cursor: auto;
    }
}

@include theme-garden {
    .button {
        color: $green900;
    }

    .userPanelButton {
        &.active {
            .button {
                color: $green700;
                background-color: $white;
            }
        }
    }
}

@include theme-living {
    .button {
        color: $white;
    }

    .userPanelButton {
        &.active {
            .button {
                color: $blue700;
                background-color: $white;
            }
        }
    }
}

@include theme-wedding {
    .button {
        color: $genoa;
    }

    .userPanelButton {
        &.active {
            .button {
                color: $genoa;
                background-color: $white;
            }
        }
    }
}
